import React, {useEffect, useState} from 'react'
import {TrainingCourse} from '../../../../dataTypes'
import {getCSSVariableValue} from '../../../assets/ts/_utils'

type Props = {
  className: string
  isCompact?: boolean
  aCourseTraining: TrainingCourse[]
  cCourseTraining: TrainingCourse[]
  fCourseTraining: TrainingCourse[]
  minus?: boolean
  onMinusButtonClick?: (courseIndex: number, courseCategory: string) => void
  potentialEmpty?: boolean
}

const TrainingPathsTable: React.FC<Props> = ({
  className,
  isCompact = true,
  aCourseTraining,
  cCourseTraining,
  fCourseTraining,
  minus = false,
  onMinusButtonClick,
  potentialEmpty,
}) => {
  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message
  const warningTime = 5000
  // console.log('potential empty in training path table', potentialEmpty)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className='d-flex w-100 h-100'>
      {!isCompact ? (
        <>
          <div className={`d-flex flex-row h-100 col-12 `}>
            <div className={`${className} pe-2`}>
              <div className='card card-body h-100 py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-5 gy-5'>
                    <thead>
                      <tr className='fw-bold fs-2 text-gray-800'>
                        <th className='min-w-100px'>Foundation Training Courses</th>
                        {minus && <th></th>}
                      </tr>
                    </thead>

                    <tbody className='min-w-100px'>
                      {fCourseTraining.length === 0 ? (
                        showLoading ? (
                          <div className='d-flex justify-content-center m-5 fw-bold fs-5'>
                            {minus === false
                              ? 'There are no trainings currently assigned. Please check back later!'
                              : potentialEmpty === true
                              ? 'There are no trainings currently assigned. Please check back later!'
                              : 'Trainings can only be assigned once the user has done the Potential Assessment. Please check back later!'}
                          </div>
                        ) : (
                          <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center'>
                            <span
                              className='spinner-border spinner-border-lg spinner-info'
                              style={{
                                width: '80px',
                                height: '80px',
                                border: 'var(--bs-spinner-border-width) solid #01a982',
                                borderRightColor: 'var(--bs-light)',
                                borderWidth: '10px',
                              }}
                            ></span>
                          </div>
                        )
                      ) : (
                        <>
                          {fCourseTraining.map((course: TrainingCourse, index) => (
                            <tr key={'fcourse' + index}>
                              <td
                                className={
                                  isCompact
                                    ? 'text-dark fw-bold text-hover-primary mb-1 fs-7'
                                    : 'text-dark fw-bold text-hover-primary mb-1 fs-6'
                                }
                              >
                                {course['course name']}
                              </td>
                              <td
                                className={
                                  isCompact
                                    ? 'text-muted fw-semibold d-block fs-8'
                                    : 'text-muted fw-semibold d-block fs-7'
                                }
                              >
                                {course['course type']}
                              </td>
                              {minus && (
                                <td>
                                  <button
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                    onClick={() =>
                                      onMinusButtonClick && onMinusButtonClick(index, 'FCourse')
                                    }
                                  >
                                    <i className='bi bi-dash' style={{color: 'red'}}></i>
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className={`${className}  pe-2`}>
              <div className='card card-body h-100 py-3'>
                <div className='table-responsive'>
                  <table className='table  align-middle gs-5 gy-5'>
                    <thead>
                      <tr className='fw-bold fs-2 text-gray-800'>
                        <th className='min-w-100px'>Core Training Courses</th>
                        {minus && <th></th>}
                      </tr>
                    </thead>

                    <tbody className='min-w-100px'>
                      {cCourseTraining.length === 0 ? (
                        showLoading ? (
                          <div className='d-flex justify-content-center m-5 fw-bold fs-5'>
                            {minus === false
                              ? 'There are no trainings currently assigned. Please check back later!'
                              : potentialEmpty === true
                              ? 'There are no trainings currently assigned. Please check back later!'
                              : 'Trainings can only be assigned once the user has done the Potential Assessment. Please check back later!'}
                          </div>
                        ) : (
                          <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center'>
                            <span
                              className='spinner-border spinner-border-lg spinner-info'
                              style={{
                                width: '80px',
                                height: '80px',
                                border: 'var(--bs-spinner-border-width) solid #01a982',
                                borderRightColor: 'var(--bs-light)',
                                borderWidth: '10px',
                              }}
                            ></span>
                          </div>
                        )
                      ) : (
                        <>
                          {cCourseTraining.map((course: TrainingCourse, index) => (
                            <tr key={'ccourse' + index}>
                              <td
                                className={
                                  isCompact
                                    ? 'text-dark fw-bold text-hover-primary mb-1 fs-7'
                                    : 'text-dark fw-bold text-hover-primary mb-1 fs-6'
                                }
                              >
                                {course['course name']}
                              </td>
                              <td
                                className={
                                  isCompact
                                    ? 'text-muted fw-semibold d-block fs-8'
                                    : 'text-muted fw-semibold d-block fs-7'
                                }
                              >
                                {course['course type']}
                              </td>
                              {minus && (
                                <td>
                                  <button
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                    onClick={() =>
                                      onMinusButtonClick && onMinusButtonClick(index, 'CCourse')
                                    }
                                  >
                                    <i className='bi bi-dash' style={{color: 'red'}}></i>
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className={`${className}`}>
              <div className='card card-body h-100 py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-5 gy-5'>
                    <thead>
                      <tr className='fw-bold fs-2 text-gray-800'>
                        <th className='min-w-100px'>Advanced Training Courses</th>
                        {minus && <th></th>}
                      </tr>
                    </thead>

                    <tbody className='min-w-100px'>
                      {aCourseTraining.length === 0 ? (
                        showLoading ? (
                          <div className='d-flex justify-content-center m-5 fw-bold fs-5'>
                            {minus === false
                              ? 'There are no trainings currently assigned. Please check back later!'
                              : potentialEmpty === true
                              ? 'There are no trainings currently assigned. Please check back later!'
                              : 'Trainings can only be assigned once the user has done the Potential Assessment. Please check back later!'}
                          </div>
                        ) : (
                          <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center'>
                            <span
                              className='spinner-border spinner-border-lg spinner-info'
                              style={{
                                width: '80px',
                                height: '80px',
                                border: 'var(--bs-spinner-border-width) solid #01a982',
                                borderRightColor: 'var(--bs-light)',
                                borderWidth: '10px',
                              }}
                            ></span>
                          </div>
                        )
                      ) : (
                        <>
                          {aCourseTraining.map((course: TrainingCourse, index) => (
                            <tr key={'acourse' + index}>
                              <td
                                className={
                                  isCompact
                                    ? 'text-dark fw-bold text-hover-primary mb-1 fs-7'
                                    : 'text-dark fw-bold text-hover-primary mb-1 fs-6'
                                }
                              >
                                {course['course name']}
                              </td>
                              <td
                                className={
                                  isCompact
                                    ? 'text-muted fw-semibold d-block fs-8'
                                    : 'text-muted fw-semibold d-block fs-7'
                                }
                              >
                                {course['course type']}
                              </td>
                              {minus && (
                                <td>
                                  <button
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                    onClick={() =>
                                      onMinusButtonClick && onMinusButtonClick(index, 'ACourse')
                                    }
                                  >
                                    <i className='bi bi-dash' style={{color: 'red'}}></i>
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${className} pe-2`} style={{height: '500px', overflow: 'hidden'}}>
            <div className='card card-body h-100 me-2 w-100 py-3'>
              <div className='table-responsive'>
                <table className='table  align-middle gs-5 gy-5 '>
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: 'var(--bs-body-bg)',
                      right: '0',
                    }}
                  >
                    <tr className='fw-bold fs-2 text-gray-800'>
                      <th className='card-title fw-bold align-items-start '>
                        Foundation Training Courses
                      </th>
                      {minus && <th></th>}
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className='min-w-100px me-2'>
                    {fCourseTraining.length === 0 ? (
                      showLoading ? (
                        <div className='d-flex justify-content-center m-5 fw-bold fs-5'>
                          {potentialEmpty === false || potentialEmpty === undefined
                            ? 'There are no trainings currently assigned. Please check back later!'
                            : 'Trainings can only be assigned once the user has done the Potential Assessment. Please check back later!'}
                        </div>
                      ) : (
                        <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center'>
                          <span
                            className='spinner-border spinner-border-lg spinner-info'
                            style={{
                              width: '80px',
                              height: '80px',
                              border: 'var(--bs-spinner-border-width) solid #01a982',
                              borderRightColor: 'var(--bs-light)',
                              borderWidth: '10px',
                            }}
                          ></span>
                        </div>
                      )
                    ) : (
                      <>
                        {fCourseTraining.map((course: TrainingCourse, index) => (
                          <tr key={'fcourse' + index}>
                            <td
                              className={
                                isCompact
                                  ? 'text-dark fw-bold text-hover-primary mb-1 fs-7'
                                  : 'text-dark fw-bold text-hover-primary mb-1 fs-6'
                              }
                            >
                              {course['course name'] === 'CompTIA Security+' ? (
                                <a
                                  href='https://auth.hpe.com/hpe/cf/?fromURI=%252Fhome%252Flitmos%252F0oaloqxcopnMNoj7r357%252F2657'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {course['course name']}
                                </a>
                              ) : (
                                course['course name']
                              )}
                            </td>
                            <td
                              className={
                                isCompact
                                  ? 'text-muted fw-semibold d-block fs-8'
                                  : 'text-muted fw-semibold d-block fs-7'
                              }
                            >
                              {course['course type']}
                            </td>
                            {minus && (
                              <td>
                                <button
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                  onClick={() =>
                                    onMinusButtonClick && onMinusButtonClick(index, 'FCourse')
                                  }
                                >
                                  <i className='bi bi-dash' style={{color: 'red'}}></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={`${className} pe-2`} style={{height: '500px', overflow: 'hidden'}}>
            <div className='card card-body h-100 py-3'>
              <div className='table-responsive'>
                <table
                  className='table  align-middle gs-5 gy-5'
                  style={{overflowY: 'scroll', height: '100%'}}
                >
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: 'var(--bs-body-bg)',
                      right: '0',
                    }}
                  >
                    <tr className='fw-bold fs-2 text-gray-800'>
                      <th className='min-w-100px'>Core Training Courses</th>
                      {minus && <th></th>}
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className='min-w-100px'>
                    {cCourseTraining.length === 0 ? (
                      showLoading ? (
                        <div className='d-flex justify-content-center m-5 fw-bold fs-5'>
                          {potentialEmpty === false || potentialEmpty === undefined
                            ? 'There are no trainings currently assigned. Please check back later!'
                            : 'Trainings can only be assigned once the user has done the Potential Assessment. Please check back later!'}
                        </div>
                      ) : (
                        <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center'>
                          <span
                            className='spinner-border spinner-border-lg spinner-info'
                            style={{
                              width: '80px',
                              height: '80px',
                              border: 'var(--bs-spinner-border-width) solid #01a982',
                              borderRightColor: 'var(--bs-light)',
                              borderWidth: '10px',
                            }}
                          ></span>
                        </div>
                      )
                    ) : (
                      <>
                        {cCourseTraining.map((course: TrainingCourse, index) => (
                          <tr key={'ccourse' + index}>
                            <td
                              className={
                                isCompact
                                  ? 'text-dark fw-bold text-hover-primary mb-1 fs-7'
                                  : 'text-dark fw-bold text-hover-primary mb-1 fs-6'
                              }
                            >
                              {course['course name'] === 'CompTIA Security+' ? (
                                <a
                                  href='https://auth.hpe.com/hpe/cf/?fromURI=%252Fhome%252Flitmos%252F0oaloqxcopnMNoj7r357%252F2657'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {course['course name']}
                                </a>
                              ) : (
                                course['course name']
                              )}
                            </td>
                            <td
                              className={
                                isCompact
                                  ? 'text-muted fw-semibold d-block fs-8'
                                  : 'text-muted fw-semibold d-block fs-7'
                              }
                            >
                              {course['course type']}
                            </td>
                            {minus && (
                              <td>
                                <button
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                  onClick={() =>
                                    onMinusButtonClick && onMinusButtonClick(index, 'CCourse')
                                  }
                                >
                                  <i className='bi bi-dash' style={{color: 'red'}}></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={`${className} pe-2`} style={{height: '500px', overflow: 'hidden'}}>
            <div className='card card-body h-100  py-3'>
              <div className='table-responsive'>
                <table
                  className='table align-middle gs-5 gy-5'
                  style={{overflowY: 'scroll', height: '100%'}}
                >
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: 'var(--bs-body-bg)',
                      right: '0',
                    }}
                  >
                    <tr className='fw-bold fs-2 text-gray-800'>
                      <th className='min-w-100px'>Advanced Training Courses</th>
                      {minus && <th></th>}
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className='min-w-100px'>
                    {aCourseTraining.length === 0 ? (
                      showLoading ? (
                        <div className='d-flex justify-content-center m-5 fw-bold fs-5'>
                          {potentialEmpty === false || potentialEmpty === undefined
                            ? 'There are no trainings currently assigned. Please check back later!'
                            : 'Trainings can only be assigned once the user has done the Potential Assessment. Please check back later!'}
                        </div>
                      ) : (
                        <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center'>
                          <span
                            className='spinner-border spinner-border-lg spinner-info'
                            style={{
                              width: '80px',
                              height: '80px',
                              border: 'var(--bs-spinner-border-width) solid #01a982',
                              borderRightColor: 'var(--bs-light)',
                              borderWidth: '10px',
                            }}
                          ></span>
                        </div>
                      )
                    ) : (
                      <>
                        {aCourseTraining.map((course: TrainingCourse, index) => (
                          <tr key={'acourse' + index}>
                            <td
                              className={
                                isCompact
                                  ? 'text-dark fw-bold text-hover-primary mb-1 fs-7'
                                  : 'text-dark fw-bold text-hover-primary mb-1 fs-6'
                              }
                            >
                              {course['course name'] === 'CompTIA Security+' ? (
                                <a
                                  href='https://auth.hpe.com/hpe/cf/?fromURI=%252Fhome%252Flitmos%252F0oaloqxcopnMNoj7r357%252F2657'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {course['course name']}
                                </a>
                              ) : (
                                course['course name']
                              )}
                            </td>
                            <td
                              className={
                                isCompact
                                  ? 'text-muted fw-semibold d-block fs-8'
                                  : 'text-muted fw-semibold d-block fs-7'
                              }
                            >
                              {course['course type']}
                            </td>
                            {minus && (
                              <td>
                                <button
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                  onClick={() =>
                                    onMinusButtonClick && onMinusButtonClick(index, 'ACourse')
                                  }
                                >
                                  <i className='bi bi-dash' style={{color: 'red'}}></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {TrainingPathsTable}
